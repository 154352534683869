import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from '../../../../utils/components/customButton'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import * as Yup from "yup";
import { Formik,Form as FMik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { postSetCostChapter, typeActionChapter } from '../../../../features/chapter/actionChapter';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getCostUnlockChapter } from '../../../../features/chapter/costUnlockChapterSlice';

const createSchema = Yup.object().shape({
    nominal: Yup.number('Kolom nominal wajib diisi number')
            .required()
            .positive()
            
  });

const SetTopUpModal = ({
    isOpen,
    setOpen,
    chapterId,
    onCancel,
    onSuccess,
}) => {
    const {storyId} = useParams();
    const dispatch = useDispatch();
    const actionChapterState = useSelector(state => state.actionChapter);
    const costUnlockChapterState = useSelector(state => state.costUnlockChapter);
    const [typeCoin, setTypeCoin] = useState('gold');

    useEffect(() => {
        if(isOpen){
            dispatch(getCostUnlockChapter({
                chapterId,
            }))
            .then(unwrapResult)
            .then(e => {
                setTypeCoin(e.data?.chapter?.coin_cost?.type ?? 'gold');
            });
        }
    },[isOpen,dispatch,chapterId]);

    const onSubmit = (amount) => {
        dispatch(postSetCostChapter({
            chapterId: chapterId,
            storyId: storyId,
            type: typeCoin,
            amount: amount,
        })).then(unwrapResult)
        .then(res => {
            setOpen(false);
            onSuccess();
            toast.success(res.message,{theme:'colored'});
        });
    }    
  return (
    <Modal
    show={isOpen}
    onHide={() => {
        setOpen(false);
        onCancel();
    }}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    style={{
        borderRadius: '1000px !important'
    }}
  >
    <Modal.Body>
    <div
        style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'stretch',
            padding: '18px'
        }}>
          <img 
            alt='lock-yellow'
            style={{
                width: '80px'
            }}
            src='/assets/icons/lock-yellow.svg' />
        <p 
            style={{
                fontSize: '30px',
                color: '#333333',
                paddingTop: '16px',
                fontWeight: '600'
            }}
            >
            Set Unlock
        </p>
        <p 
            style={{
                fontSize: '20px',
                color: '#666E7D',
                paddingTop: '16px',
                fontWeight: '500'
            }}
            >
            Pilih jenis koin unlock
        </p>
        <div
            style={{
                display: 'flex',
                marginBottom: '12px',
            }}>
                <ItemTypeCoin
                    onClick={() => setTypeCoin('gold')}
                    isActive={typeCoin==='gold'}
                    label="Koin Emas"
                     />
                <ItemTypeCoin
                    onClick={() => setTypeCoin('silver')}
                    isActive={typeCoin==='silver'}
                    label="Koin Perak"
                     />
                <ItemTypeCoin
                    onClick={() => setTypeCoin('both')}
                    isActive={typeCoin==='both'}
                    label="Keduanya"
                     />
        </div>
        <div style={{height: '12px'}} />
        
        <Formik
            initialValues={{
                nominal: costUnlockChapterState.data?.coin_cost?.amount ?? '',
            }}
            validationSchema={createSchema}
            enableReinitialize
            onSubmit={(values, {setSubmitting}) => {
                onSubmit(values.nominal);
            }}
        >
            {props => (
                <FMik>
                     <Form.Label
                        style={{
                            fontSize: '20px',
                            fontWeight: '500',
                            color: '#666E7D'
                        }}>
                        Nominal
                    </Form.Label>
                <InputGroup className="">
                    <div
                        style={{
                            backgroundColor: '#E6E8EB',
                            width: '55px',
                            height: '55px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '8px 0 0 8px'
                        }}>
                        <img 
                            alt='gold-coin' 
                            width={'25px'}
                            height={'25px'}
                            src={typeCoin === 'gold' || typeCoin == 'both' ? '/assets/icons/gold-coin.svg' : "/assets/icons/silver-coin.svg"} />
                    </div>
                    {typeCoin === 'both' && (
                         <div
                            style={{
                                backgroundColor: '#E6E8EB',
                                width: '55px',
                                height: '55px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <img
                             alt='gold-coin'
                             width={'25px'}
                             height={'25px'}
                              src='/assets/icons/silver-coin.svg' />
                        </div>
                    )}
                   
                    <Form.Control
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.nominal}
                        name="nominal"
                        placeholder="0"
                    />
                    </InputGroup>
                    {props.touched.nominal && props.errors.nominal && (
                        <Form.Text className="text-danger">
                            {props.errors.nominal}
                        </Form.Text>
                    )}
                    <div style={{height: '24px'}} />
                    <CustomButton
                        isLoading={
                            actionChapterState.isLoading && actionChapterState.typeAction===typeActionChapter.setCost
                        }
                        styles={{
                            minWidth: '100px'
                        }}
                        onClick={props.handleSubmit}
                    >
                        Simpan
                    </CustomButton>
                </FMik>
            )}
           
        </Formik>
       
    </div>

    </Modal.Body>
   
  </Modal>
  )
}

const ItemTypeCoin = ({
    onClick,
    label,
    isActive,
}) => {
    return (
        <CustomButton
            onClick={onClick}
            styles={{
                flex: 1,
                minHeight: '45px',
                margin: '0 12px',
                backgroundColor: isActive ? '#20BF6B' : '#E6E8EB',
                color: isActive ? 'white': '#929AA7',
            }}
            size='sm' >
            {label}
        </CustomButton>
    );
}
export default SetTopUpModal