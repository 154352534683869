import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import Delta from "quill-delta";

// const biangKerok = `<div style=\"text-align: center;\"><i><b><sub><br></sub></b></i></div>
// <div style=\"text-align: center;\"><i><b><sub>Hadiahnya :</sub></b></i></div>
// <div style=\"text-align: center; \"><i><b><sub>1.<span style=\"white-space:pre\">\t</span>
// 50 koin emas untuk 30 pembaca</sub></b></i></div><div style=\"text-align: center;\"><i><b><sub>2.
// <span style=\"white-space:pre\">\t</span>Dua novel cetakku berjudul Dunia tanpa Lara ( bisa ditukar dengan 500 koin emas atau uang senilai 50K )</sub>
// </b></i></div><div style=\"text-align: center;\"><i><b><sub><br></sub></b></i></div><div style=\"text-align: center;\"><br></div><div><br></div>`;

interface QuillEditorProps {
  value: string;
  onChange?: (content: string) => void;
  onReady?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  modules?: any;
  formats?: string[];
  maxHeight?: string;
}

const QuillEditor: React.FC<QuillEditorProps> = ({
  value,
  onChange,
  placeholder = "Tulis isi cerita disini...",
  readOnly = false,
  modules = {},
  formats = [],
  maxHeight = "400px",
  onReady,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill | null>(null);

  const [firstInit, setFirstInit] = React.useState<boolean>(true);

  const cleanHtml = (htmlContent: string) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(
      htmlContent
        .replace(/<div\b([^>]*)>/gi, "<p$1>")
        .replace(/<\/div>/gi, "</p>")
        .replace(/<\/?(sub|sup)>/g, ""),
      "text/html"
    );

    // Hapus semua <span> tetapi pertahankan kontennya
    document.querySelectorAll("span").forEach((element) => {
      const textNode = document.createTextNode(element.textContent.trim());
      element.replaceWith(textNode);
    });

    // Hapus atribut kecuali text-style dan padding dari style
    document.querySelectorAll("[style], [class], [dir]").forEach((element) => {
      if (element.hasAttribute("style")) {
        const allowedStyles = ["padding", "text-align"];
        const styleValues = element
          .getAttribute("style")
          .split(";")
          .map((style) => style.trim())
          .filter((style) =>
            allowedStyles.some((allowed) => style.startsWith(allowed))
          )
          .join("; ");

        element.setAttribute("style", styleValues);
      }
      element.removeAttribute("class");

      element.removeAttribute("dir");
    });

    // Trim whitespace di seluruh dokumen
    return document.body.innerHTML
      .trim()
      .replace(
        /style="text-align:\s*(left|center|right|justify);?"/g,
        (match, align) => {
          return `class="ql-align-${align}"`;
        }
      );
  };

  const onTextChange = () => {
    if (onChange && quillRef.current) {
      try {
        const content = quillRef.current.root.innerHTML;

        onChange(content);
      } catch (e) {
      }
    }
  };

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    // Default modules configuration
    const defaultModules = {
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ indent: "-1" }, { indent: "+1" }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { align: ["", "center", "right", "justify"] },
        ],
      ],
      ...modules,
    };

    // Initialize Quill
    quillRef.current = new Quill(editorRef.current, {
      theme: "snow",
      modules: defaultModules,
      formats: formats.length > 0 ? formats : undefined,
      placeholder,
      readOnly,
    });

    quillRef.current.clipboard.addMatcher(
      Node.ELEMENT_NODE,
      function (node: Node, delta: Delta) {
      

        delta.ops.forEach((op) => {
          if (op.attributes) {
            delete op.attributes.font;
            delete op.attributes.size;
            delete op.attributes.color;
            delete op.attributes.background;
          }
        });

        return delta;
      }
    );

    // Set initial content
    quillRef.current.root.innerHTML = "";

    // Handle content changes
    quillRef.current.on("text-change", onTextChange);

    if (editorRef.current) {
      onReady && onReady();
      const editorArea = editorRef.current.querySelector(".ql-editor");

      if (editorArea) {
        (editorArea as HTMLElement).style.minHeight = "300px";
        (editorArea as HTMLElement).style.overflowY = "auto";
        (editorArea as HTMLElement).style.fontSize = "16px";
        (editorArea as HTMLElement).style.fontFamily = "Poppins";
        (editorArea as HTMLElement).style.fontWeight = "500";
        (editorArea as HTMLElement).setAttribute("translate", "no");
        // (editorArea as HTMLElement).style.color = "#666E7D";
      }
    }

    // Cleanup
    return () => {
      if (quillRef.current) {
        quillRef.current.off("text-change", onTextChange);
      }
    };
  }, []);

  const convertToHTML = (text: string): string => {
    let html = text;

    // WhatsApp style formatting
    // Bold: *text*
    html = html.replace(/\*(.*?)\*/g, "<strong>$1</strong>");

    // Italic: _text_
    html = html.replace(/_(.*?)_/g, "<em>$1</em>");

    // Strikethrough: ~text~
    html = html.replace(/~(.*?)~/g, "<s>$1</s>");

    // Markdown formatting
    // Headers
    html = html.replace(/^# (.*$)/gm, "<h1>$1</h1>");
    html = html.replace(/^## (.*$)/gm, "<h2>$1</h2>");
    html = html.replace(/^### (.*$)/gm, "<h3>$1</h3>");

    // Lists
    // Unordered lists
    html = html.replace(/^\- (.*$)/gm, "<ul><li>$1</li></ul>");
    html = html.replace(/^\* (.*$)/gm, "<ul><li>$1</li></ul>");

    // Ordered lists
    html = html.replace(/^\d+\. (.*$)/gm, "<ol><li>$1</li></ol>");

    // Links
    html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');

    // Code blocks
    html = html.replace(/```([\s\S]*?)```/g, "<pre><code>$1</code></pre>");

    // Inline code
    html = html.replace(/`([^`]+)`/g, "<code>$1</code>");

    // Blockquotes
    html = html.replace(/^> (.*$)/gm, "<blockquote>$1</blockquote>");

    // Clean up any double spaces
    html = html.replace(/\s\s+/g, " ");

    return html;
  };

  // Update content if value prop changes
  useEffect(() => {
    if (quillRef.current && value !== quillRef.current.root.innerHTML) {
      try {
        if (firstInit) {
          quillRef.current.root.innerHTML = cleanHtml(value);
          setFirstInit(false);
        } else {
          quillRef.current.root.innerHTML = value;
        }
      } catch (e) {
      }
    }
  }, [value]);

  return (
    <div className="quill-editor-container">
      <div ref={editorRef} />
    </div>
  );
};

export default QuillEditor;
