import { createSlice } from "@reduxjs/toolkit";

interface StoryDraftProps {
  page: number;
  forceRefetch: boolean;
}

const initialState: StoryDraftProps = {
  page: 1,
  forceRefetch: false,
};

const storyDraftSlice = createSlice({
  name: "storyDraftSlice",
  initialState,

  reducers: {
    forceRefetchStoryDraft: (state) => {
      state.forceRefetch = true;
      state.page = 1;
    },
    setRefetchStoryDraft: (state, { payload }: { payload: boolean }) => {
      state.forceRefetch = payload;
    },
    setPageStoryDraft: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
});

// Export actions and reducer
export const {
  forceRefetchStoryDraft,
  setPageStoryDraft,
  setRefetchStoryDraft,
} = storyDraftSlice.actions;
export default storyDraftSlice.reducer;
