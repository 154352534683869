import React from "react";
import { Container, Card, Nav } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "./components/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import MyStoryDraft from "../../../pages/myStory/draft/myStoryDraft";
import MyStoryRelease from "../../../pages/myStory/release/myStoryRelease";
import Sizedbox from "../../../utils/components/sizedbox";
import { ListStoryReleased } from "./released/ListStoryReleased";
import ListStoryDraft from "./draft/ListStoryDraft";
import AppBarMyStory from "./components/AppBarMyStory";

const StoryPage = () => {
  const [selectedTab, setSelectedTab] = React.useState("publish");
  const [pageReleased, setPageReleased] = React.useState(1);

  return (
    <div>
      <AppBarMyStory />
      <Container>
        <Card
          className={`my-4`}
          style={{ borderRadius: "8px", width: isMobile ? "100%" : "40%" }}
        >
          <Card.Body className="p-1 ">
            <Nav
              variant="pills"
              activeKey={selectedTab}
              onSelect={(selectedKey) => {
                setSelectedTab(selectedKey);
              }}
            >
              <Nav.Item className="w-50 text-center">
                <Nav.Link eventKey="publish">Diterbitkan</Nav.Link>
              </Nav.Item>
              <Nav.Item className="w-50 text-center">
                <Nav.Link eventKey="draft">Draf</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Body>
        </Card>
        {selectedTab === "publish" && <ListStoryReleased />}
        {selectedTab === "draft" && <ListStoryDraft />}

        {isMobile && <Sizedbox height="70px" width={undefined} />}
      </Container>
    </div>
  );
};

export default StoryPage;
