import { Button, Card, Overlay } from "react-bootstrap";
import { useState, useRef, forwardRef, useImperativeHandle } from "react";
import ItemMenuEditMyStory from "../../itemMenuEditMyStory";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { postDeleteChapter } from "../../../../../features/chapter/actionChapter";
import { unwrapResult } from "@reduxjs/toolkit";
import ModalConfirm from "../../../../../utils/components/modalConfirm";
import ModalAlert from "../../../../../utils/components/modalAlert";
import PreviewChapter from "../preview/previewChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { setPageStoryReleased } from "../../../../../features/story/data/slice/storyReleasedSlice";
import { setPageStoryDraft } from "../../../../../features/story/data/slice/storyDraftSlice";
import { storyApi } from "../../../../../features/story/data/api/storyApi";
import { convertQuillContent } from "../../../../../utils/helpers/quilInlineCssHelper";

const PopupMenuChapter = forwardRef(
  (
    {
      isMonetize = true,
      isDeleteBtn = false,
      type,
      media = [],
      setNavigate,
      show,
      setShow,
    },
    ref
  ) => {
    const [fullText, setFullText] = useState("");
    const [title, setTitle] = useState("");
    const dispatch = useDispatch();
    const { chapterId } = useParams();
    const { storyId } = useParams();
    const target = useRef(null);
    const [isDialogDeleteOpen, setDialogDeleteOpen] = useState(false);
    const actionChapter = useSelector((state) => state.actionChapter);
    const [isPreviewShow, setPreviewShow] = useState(false);

    useImperativeHandle(ref, () => ({
      setValues: (newFullText, newTitle) => {
        setFullText(convertQuillContent(newFullText));
        setTitle(newTitle);
      },
    }));

    const onDeleteChapter = () => {
      setShow(false);
      dispatch(
        postDeleteChapter({
          chapterId: chapterId,
          storyId: storyId,
        })
      )
        .then(unwrapResult)
        .then((_) => {
          dispatch(setPageStoryReleased(1));
          dispatch(setPageStoryDraft(1));
          dispatch(
            storyApi.util.invalidateTags([
              { type: "StoriesReleased", id: "LIST" },
            ])
          );
          dispatch(
            storyApi.util.invalidateTags([{ type: "StoriesDraft", id: "LIST" }])
          );
          setNavigate(`/edit-story/${storyId}`);
        });
    };
    const [isDialogMonetizeShow, setDialogMonetizeShow] = useState(false);

    const onPreviewChapter = () => {
      setShow(false);
      setPreviewShow(true);
    };

    return (
      <>
        <ModalConfirm
          title="Hapus Bab"
          description="Apakah anda yakin ingin menghapus babmu?"
          show={isDialogDeleteOpen}
          onYesClick={onDeleteChapter}
          isLoading={actionChapter.isLoading}
          onHide={() => setDialogDeleteOpen(false)}
        />
        <PreviewChapter
          show={isPreviewShow}
          type={type}
          fullText={fullText}
          title={title}
          media={media}
          onHide={() => setPreviewShow(false)}
        />
        <ModalAlert
          show={isDialogMonetizeShow}
          onHide={() => setDialogMonetizeShow(false)}
          description="Cerita ini telah dimonetisasi, untuk dapat melakukan fungsi ini silahkan hubungi admin"
        />
        <Button
          ref={target}
          onClick={() => setShow(!show)}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            backgroundColor: "transparent",
            border: "none",
            color: "black",
            fontSize: "20px",
            boxShadow: "none",
          }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <Card
              {...props}
              style={{
                position: "absolute",
                ...props.style,
              }}
            >
              <Card.Body>
                <ItemMenuEditMyStory
                  src="/assets/preview.png"
                  onClick={onPreviewChapter}
                  title="Pratinjau"
                />
                {isDeleteBtn && (
                  <>
                    <hr></hr>
                    <ItemMenuEditMyStory
                      textColor="red"
                      src="/assets/SAMPAH.png"
                      onClick={() => {
                        setShow(false);
                        if (isMonetize) {
                          setDialogMonetizeShow(true);
                        } else {
                          setDialogDeleteOpen(true);
                        }
                      }}
                      title="Hapus Bab"
                    />
                  </>
                )}
              </Card.Body>
            </Card>
          )}
        </Overlay>
      </>
    );
  }
);

export default PopupMenuChapter;
