import React, { useEffect } from "react";
import { useGetStoriesReleasedQuery } from "../../data/api/storyApi";
import InfiniteScroll from "react-infinite-scroll-component";
import MyStoryShimmer from "../../../../pages/myStory/components/myStoryShimmer";
import ItemStoryReleased from "./ItemStoryReleased";
import { Row } from "react-bootstrap";
import MyStoryEmpty from "../../../../pages/myStory/components/myStoryEmpty";
import MyStoryError from "../../../../pages/myStory/components/myStoryError";
import { AppDispatch, RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setPageStoryReleased,
  setRefetchStoryReleased,
} from "../../data/slice/storyReleasedSlice";

export const ListStoryReleased: React.FC = () => {
  const storyReleasedState = useSelector(
    (state: RootState) => state.storyReleased
  );
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, isError, isSuccess, refetch } =
    useGetStoriesReleasedQuery({
      pageNumber: storyReleasedState.page,
      forceRefetch: storyReleasedState.forceRefetch,
    });

  useEffect(() => {
    dispatch(setRefetchStoryReleased(false));
  }, [dispatch, isSuccess]);

  return (
    <>
      {isLoading && <MyStoryShimmer length={10} />}
      {isSuccess && data?.release?.length === 0 && <MyStoryEmpty />}
      {isSuccess && data?.release?.length > 0 && (
        <InfiniteScroll
          loader={<MyStoryShimmer length={6} />}
          dataLength={data?.release?.length ?? 0}
          hasMore={data?.is_loadmore}
          next={() =>
            dispatch(setPageStoryReleased(storyReleasedState.page + 1))
          }
        >
          <Row>
            {data?.release?.map((item, index) => (
              <ItemStoryReleased key={index} model={item} />
            ))}
          </Row>
        </InfiniteScroll>
      )}
      {isError && <MyStoryError onError={refetch} />}
    </>
  );
};
