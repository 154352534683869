import React from "react";
import { StoryModel } from "../../data/models/storyModel";
import { useNavigate } from "react-router";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { Card, Col } from "react-bootstrap";
import CoverBook from "../../../../utils/components/coverBook";
import { formatCash } from "../../../../utils/helpers/numberFormat";

interface ItemStoryDraftProps {
  model: StoryModel;
}

export const ItemStoryDraft: React.FC<ItemStoryDraftProps> = ({ model }) => {
  const navigate = useNavigate();
  var t = new Date(model?.updated_at);
  var updatedAt = moment(t).fromNow();

  return (
    <Col md={6} key={model._id}>
      <Card
        className="mb-4 item-story"
        onClick={() =>
          navigate(
            `/edit-chapter/${model.story._id}/${model._id}?type=${model.type}&status=draft`
          )
        }
      >
        <Card.Body>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "30% 70%" : "27.5% 72.5%",
              gridTemplateRows: isMobile ? "140px" : "200px",
            }}
          >
            <div style={{ position: "relative" }}>
              <CoverBook
                src={model.cover_img.url}
                width="100%"
                height="100%"
                style={{ margin: "auto 0" }}
                onClick={undefined}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  color: "white",
                  margin: "5px",
                  padding: `${isMobile ? "5px" : "10px"} 0`,
                  borderRadius: "7px",
                  textAlign: "center",
                  backgroundColor: model.in_progress ? "#FFBA07" : "#20BF6B",
                  fontSize: "11px",
                  fontWeight: 600,
                }}
              >
                {model.in_progress ? "Belum Selesai" : "Selesai"}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                paddingLeft: "20px",
              }}
            >
              <h5
                style={{
                  fontSize: isMobile ? "18px" : "24px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  lineClamp: 1,
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {model?.story?.title}
              </h5>
              <p
                style={{
                  fontSize: "14px",
                  color: "#20BF6B",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  lineClamp: 1,
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {model?.title}
              </p>
              {!isMobile && (
                <p
                  style={{
                    fontSize: "13px",
                    color: "#714d69",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    lineClamp: 2,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {model?.story?.synopsis}
                </p>
              )}
              <p
                style={{
                  fontSize: "11px",
                  color: "#929AA7",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  lineClamp: 1,
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                }}
              >
                Terakhir diperbaharui {updatedAt}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <img
                  alt="eye"
                  src="/assets/eye.png"
                  width={isMobile ? "15px" : "22px"}
                />{" "}
                &nbsp;
                <span className="total-subs-view">
                  {" "}
                  {formatCash(model.stats.total_view)}{" "}
                </span>
                &nbsp;&nbsp;
                <img
                  alt="bookmark"
                  src="/assets/bookmark.png"
                  width={isMobile ? "15px" : "22px"}
                />{" "}
                &nbsp;
                <span className="total-subs-view">
                  {" "}
                  {formatCash(model.stats.total_subs)}{" "}
                </span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
