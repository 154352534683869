import axios from "axios";
import MyAxios from "../../utils/helpers/myAxios";
import { ErrorResponse, SessionKey } from "../../utils/values/constants";
import Cookies from "js-cookie";

const AuthService = (() => {
  const postLogin = async ({ username, password }) => {
    try {
      var body = {
        username: username,
        password: password,
        device_type: "web-writer",
      };
      const response = await MyAxios.post("/auth/login", body);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const checkLogin = async (token) => {
    try {
      MyAxios.defaults.baseURL = process.env.REACT_APP_REDIRECT_KBM;
      const response = await MyAxios.post(`/api/check-login?token=${token}`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const postGenerateToken = async () => {
    try {
      const token = Cookies.get(SessionKey.token);
      MyAxios.defaults.baseURL = process.env.REACT_APP_REDIRECT_KBM;
      MyAxios.defaults.headers.common["bearer"] = token;
      const response = await MyAxios.post(`/api/generate-login`);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const postLoginGoogle = async ({ uid, name, profileImg, email, token }) => {
    try {
      var body = {
        uid: uid,
        name: name,
        profile_img: profileImg,
        email: email,
        token: token,
        device_type: "web-writer",
      };
      const response = await MyAxios.post("/auth/login/google", body);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      return response;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  const validationRecaptcha = async ({ token }) => {
    try {
      var body = {
        token: token,
      };
      MyAxios.defaults.baseURL = process.env.REACT_APP_REDIRECT_KBM;
      const response = await MyAxios.post("/api/validation-recaptcha-v3", body);
      if (!response.data.status) {
        return Promise.reject(response.data);
      }
      MyAxios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
      return response;
    } catch (e) {
      throw ErrorResponse;
    }
  };

  return {
    postGenerateToken: postGenerateToken,
    postLogin: postLogin,
    postLoginGoogle: postLoginGoogle,
    checkLogin: checkLogin,
    validationRecaptcha: validationRecaptcha,
  };
})();

export default AuthService;
