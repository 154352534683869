import { baseApi } from "../../../../core/baseApi";
import { SessionKey } from "../../../../utils/values/constants";
import { DataStoryModel, ResponseStoryModel } from "../models/storyModel";
import Cookies from "js-cookie";
import { setPageStoryDraft } from "../slice/storyDraftSlice";
import { setPageStoryReleased } from "../slice/storyReleasedSlice";
import { ResponsePostModel } from "../../../../core/responsePostModel";

export const storyApi = baseApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getStoriesDraft: builder.query<
      DataStoryModel,
      { pageNumber: number; forceRefetch: boolean }
    >({
      query: ({ pageNumber = 1 }) => ({
        url: `/api/profile/${Cookies.get(SessionKey.userId)}/story/draft`,
        params: {
          page_size: 10,
          page_number: pageNumber,
        },
      }),
      transformResponse(baseQueryReturnValue: ResponseStoryModel, meta, arg) {
        return baseQueryReturnValue.data;
      },
      keepUnusedDataFor: 600,
      async onCacheEntryAdded(arg, { dispatch, cacheEntryRemoved }) {
        await cacheEntryRemoved;
        dispatch(setPageStoryDraft(1));
      },
      // klo page number nya berubah maka akan nge get ulang.
      forceRefetch({ currentArg, previousArg, state }) {
        return (
          currentArg?.pageNumber !== previousArg?.pageNumber ||
          currentArg?.forceRefetch
        );
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: "StoriesDraft", id: "LIST" }, // Untuk invalidasi daftar secara umum
              ...result.draft.map((story) => ({
                type: "StoriesDraft" as const,
                id: story._id,
              })), // Untuk invalidasi per item
            ]
          : [{ type: "StoriesDraft", id: "LIST" }],
      //
      serializeQueryArgs({ endpointName, queryArgs }) {
        return `${endpointName}`;
      },
      // untuk loadmore menambahkan data terbaru ke list yang sudah ada.
      merge(currentCacheData, responseData, otherArgs) {
        if (currentCacheData && otherArgs.arg.pageNumber > 1) {
          return {
            ...currentCacheData,
            ...responseData,
            draft: [...currentCacheData.draft, ...responseData.draft],
          };
        } else {
          return responseData;
        }
      },
    }),
    getStoriesReleased: builder.query<
      DataStoryModel,
      { pageNumber: number; forceRefetch: boolean }
    >({
      query: ({ pageNumber = 1 }) => ({
        url: `/api/profile/${Cookies.get(SessionKey.userId)}/story/released`,
        params: {
          page_size: 10,
          page_number: pageNumber,
          query: "",
        },
      }),
      transformResponse(baseQueryReturnValue: ResponseStoryModel, meta, arg) {
        return baseQueryReturnValue.data;
      },
      keepUnusedDataFor: 600,
      async onCacheEntryAdded(arg, { dispatch, cacheEntryRemoved }) {
        await cacheEntryRemoved;
        dispatch(setPageStoryReleased(1));
      },
      // klo page number nya berubah maka akan nge get ulang.
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.pageNumber !== previousArg?.pageNumber ||
          currentArg?.forceRefetch
        );
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              { type: "StoriesReleased", id: "LIST" }, // Untuk invalidasi daftar secara umum
              ...result.release.map((story) => ({
                type: "StoriesReleased" as const,
                id: story._id,
              })), // Untuk invalidasi per item
            ]
          : [{ type: "StoriesReleased", id: "LIST" }],
      //
      serializeQueryArgs({ endpointName, queryArgs }) {
        return `${endpointName}`;
      },
      // untuk loadmore menambahkan data terbaru ke list yang sudah ada.
      merge(currentCacheData, responseData, otherArgs) {
        if (currentCacheData && otherArgs.arg.pageNumber > 1) {
          return {
            ...currentCacheData,
            ...responseData,
            release: [...currentCacheData.release, ...responseData.release],
          };
        } else {
          return responseData;
        }
      },
    }),
    getStory: builder.query<DataStoryModel, { storyId: string }>({
      query: ({ storyId }) => ({
        url: `/api/story/edit/new/${storyId}`,
      }),
      transformResponse(baseQueryReturnValue: ResponseStoryModel, meta, arg) {
        return baseQueryReturnValue.data;
      },
      keepUnusedDataFor: 600,
    }),
    upsertStory: builder.mutation<
      ResponsePostModel,
      {
        storyId?: string;
        title: string;
        category: string;
        tags: string[];
        synopsis: string;
        coverImg: string;
        isShowWordCount: string;
      }
    >({
      query: (params) => ({
        url: `/api/story/upsert`,
        params: {
          story_id: params.storyId,
          title: params.title,
          category: params.category,
          tags: params.tags,
          synopsis: params.synopsis,
          cover_img: params.coverImg,
          attribute_read_time: "active",
          attribute_view_count: "active",
          attribute_words_count: params.isShowWordCount,
        },
        method: "POST",
      }),
      invalidatesTags: ["StoriesDraft", "StoriesReleased"],
    }),
  }),
});

export const {
  useGetStoriesDraftQuery,
  useGetStoriesReleasedQuery,
  useUpsertStoryMutation,
} = storyApi;
