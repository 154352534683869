import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../app/store";
import { useGetStoriesDraftQuery } from "../../data/api/storyApi";
import MyStoryShimmer from "../../../../pages/myStory/components/myStoryShimmer";
import MyStoryEmpty from "../../../../pages/myStory/components/myStoryEmpty";
import InfiniteScroll from "react-infinite-scroll-component";
import { Row } from "react-bootstrap";
import { ItemStoryDraft } from "./ItemStoryDraft";
import MyStoryError from "../../../../pages/myStory/components/myStoryError";
import {
  setPageStoryDraft,
  setRefetchStoryDraft,
} from "../../data/slice/storyDraftSlice";

const ListStoryDraft: React.FC = () => {
  const storyDraftState = useSelector((state: RootState) => state.storyDraft);
  const dispatch = useDispatch<AppDispatch>();
  const { data, isLoading, isError, isSuccess, refetch, } =
    useGetStoriesDraftQuery({
      pageNumber: storyDraftState.page,
      forceRefetch: storyDraftState.forceRefetch,
    });

  useEffect(() => {
    dispatch(setRefetchStoryDraft(false));
  }, [dispatch, isSuccess]);

  return (
    <>
      {isLoading && <MyStoryShimmer length={10} />}
      {isSuccess && data?.draft?.length === 0 && <MyStoryEmpty />}
      {isSuccess && data?.draft?.length > 0 && (
        <InfiniteScroll
          loader={<MyStoryShimmer length={6} />}
          dataLength={data?.draft?.length ?? 0}
          hasMore={data?.is_loadmore}
          next={() => dispatch(setPageStoryDraft(storyDraftState.page + 1))}
        >
          <Row>
            {data?.draft?.map((item, index) => (
              <ItemStoryDraft key={index} model={item} />
            ))}
          </Row>
        </InfiniteScroll>
      )}
      {isError && <MyStoryError onError={refetch} />}
    </>
  );
};

export default ListStoryDraft;
