import { createSlice } from "@reduxjs/toolkit";

interface StoryReleasedProps {
  page: number;
  forceRefetch: boolean;
}

const initialState: StoryReleasedProps = {
  page: 1,
  forceRefetch: false,
};

const storyReleasedSlice = createSlice({
  name: "storyReleasedSlice",
  initialState,
  reducers: {
    forceRefetchStoryReleased: (state) => {
      state.forceRefetch = true;
      state.page = 1;
    },
    setRefetchStoryReleased: (state, { payload }: { payload: boolean }) => {
      state.forceRefetch = payload;
    },
    setPageStoryReleased: (state, { payload }: { payload: number }) => {
      state.page = payload;
    },
  },
});

// Export actions and reducer
export const { forceRefetchStoryReleased, setPageStoryReleased, setRefetchStoryReleased } = storyReleasedSlice.actions;
export default storyReleasedSlice.reducer;
