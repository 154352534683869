import { Navbar, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CircleProfile from "../../../../utils/components/circleProfile";
import CustomButton from "../../../../utils/components/customButton";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { postGenerateToken } from "../../../auth/authSlice";

const AppBarMyStory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const generateTokenState = useSelector((state) => state.auth);

  const redirectKbm = (e) => {
    e.preventDefault();
    if (!generateTokenState.isLoading) {
      dispatch(postGenerateToken());
    }
  };

  return (
    <>
      <Navbar
        bg="white"
        style={{ borderBottom: "2px solid #e6e8eb" }}
        sticky="top"
      >
        <Container>
          <Navbar.Brand>
            <a
              href="#"
              onClick={redirectKbm}
              style={{
                cursor: generateTokenState.isLoading ? "progress" : "pointer",
              }}
            >
              <img
                alt="kbm logo landscape"
                src="/assets/logo-kbm-landscape.png"
                width={150}
              />
            </a>
          </Navbar.Brand>
          {!isMobile && (
            <Navbar.Text
              style={{
                margin: "auto 0",
                color: "#1E222B",
              }}
            >
              <p className="title-appbar">Cerita Saya</p>
            </Navbar.Text>
          )}
          <div>
            {!isMobile && (
              <CustomButton size="sm" onClick={() => navigate("/create-story")}>
                Cerita Baru
              </CustomButton>
            )}
            <CircleProfile />
          </div>
        </Container>
      </Navbar>
      {isMobile && (
        <Navbar fixed="bottom" bg="white">
          <Container fluid>
            <CustomButton
              className="w-100"
              onClick={() => navigate("/create-story")}
            >
              Cerita Baru
            </CustomButton>
          </Container>
        </Navbar>
      )}
    </>
  );
};

export default AppBarMyStory;
