import {
  BaseQueryFn,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
import { SessionKey } from "../utils/values/constants";
import { signOut, getAuth } from "firebase/auth";
import { initializeApp } from "@firebase/app";
import { firebaseConfig } from "../utils/helpers/firebaseConfig";
import { isRejectedWithValue } from "@reduxjs/toolkit";

export interface CustomError {
  data: {
    message: string;
    stack: string;
  };
  status: number;
}

export const rtkQueryMiddleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = action.payload?.status;
    if (status === 401) {
      const app = initializeApp(firebaseConfig);
      const auth = getAuth(app);
      signOut(auth);
      Cookies.remove(SessionKey.profileImg);
      Cookies.remove(SessionKey.profileName);
      Cookies.remove(SessionKey.token);
      Cookies.remove(SessionKey.userId);
      const kbmLink = process.env.REACT_APP_REDIRECT_KBM;
      const redirectLink = `${kbmLink}/logout-write`;
      window.location.replace(redirectLink);
    }
  }
  return next(action);
};

export const baseApi = createApi({
  reducerPath: "baseApi",
  tagTypes: ["StoriesReleased", "StoriesDraft"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,

    prepareHeaders: async (headers, _) => {
      const token = Cookies.get(SessionKey.token);
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
    validateStatus: (response, body) => {
      return response.status === 200 && body.status;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
  endpoints: () => ({}),
});
